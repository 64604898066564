@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  right: 0;
  padding-right: 5%;
  bottom: 10%;
  background-color: transparent;
}

.rs-sidenav-subtle {
  background-color: transparent !important;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-family: "NTR", sans-serif;
  letter-spacing: 0em;
  line-height: 1.6em;
  font-size: 16px;
  padding: 0.2em;
  font-weight: bold;
}

a {
  text-decoration: none !important;
  color: var(--green-bright) !important;
}

a:hover {
  color: var(--lightest-slate) !important;
}

.sidebar-logos {
  padding-top: 42px;
  width: 120px; /* Increased to accommodate theme toggle */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.sidebar-logos a {
  color: var(--green-bright) !important;
  transition: color 0.2s ease;
}

.sidebar-logos a:hover {
  color: var(--lightest-slate) !important;
}

@media only screen and (max-width: 600px) {
  .sidebar-nav {
    width: 100%;
    height: 50px;
  }

  .sidebar-links {
    padding-left: 20px;
    flex-direction: row;
    text-align: right;
    font-size: 9px;
  }

  .sidebar-logos {
    padding-top: 0px;
    width: 140px; /* Increased for mobile */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 50%;
  }

  .theme-toggle {
    padding: 4px; /* Smaller padding on mobile */
  }
}

@media only screen and (max-width: 600px) {
  .sidebar-nav {
    position: unset;
    align-items: center;
  }
  .sidebar-logos {
    width: 120px;
    padding-left: unset;
  }
}
#intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15%;
  padding-top: 18%;
  min-height: 100vh;
}

canvas {
  width: 200px !important;
}

.Typist {
  color: var(--lightest-slate);
  text-align: center;
  font-size: 76px;
  margin-top: -30px;
}
.Typist .Cursor--blinking {
  color: var(--green-bright);
  padding-left: 4px;
}

.intro-title {
  font-family: "NTR", sans-serif;
  font-size: 86px;
}
.intro-name {
  font-family: "NTR", sans-serif;
  color: var(--green-bright);
  font-weight: bold;
  font-size: 86px;
}

.intro-subtitle {
  margin-top: -36px;
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-weight: 300;
  font-size: 48px;
}

.intro-desc {
  color: var(--slate);
  font-family: "NTR", sans-serif;
  font-size: 22px;
  max-width: 40%;
  text-align: justify;
  padding-bottom: 50px;
}

.intro-contact {
  font-size: 22px;
  font-weight: bolder;
  font-family: "NTR", sans-serif;
  padding: 10px 30px;
  cursor: pointer;
  border: 1px solid var(--green-bright);
  border-radius: 4px;
}

.intro-contact:hover {
  background-color: var(--lightest-navy);
}

b {
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  #gazi-model {
    display: none;
  }

  #intro {
    min-height: unset;
    padding-left: unset;
    margin: 0;
    align-items: center;
  }

  .intro-title {
    font-size: 46px;
  }
  .intro-name {
    font-size: 46px;
  }

  .Typist {
    font-size: 36px;
  }

  .intro-subtitle {
    margin: 0;
    font-size: 28px;
    text-align: center;
  }

  .intro-desc {
    font-size: 18px;
    text-align: center;
    max-width: unset;
    padding-bottom: 40px;
  }

  .intro-contact {
    font-size: 16px;
    align-self: center;
    margin-left: 30%;
  }
}

#experience {
  padding-left: 15%;
  min-height: 60vh;
}

.joblist-job-title {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: var(--lightest-slate);
}

.joblist-job-company {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  color: var(--green-bright);
  font-weight: bold;
}

.joblist-duration {
  font-family: "NTR", sans-serif;
  font-size: 18px;
  color: var(--slate);
}

#vertical-tabpanel {
  margin-top: -28px;
  padding-left: 25px;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-align: left;
  align-items: flex-start !important;
  font-family: "NTR", sans-serif;
}

.MuiButtonBase-root {
  outline: none !important;
  color: var(--lightest-slate) !important;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: var(--green-bright) !important;
}
.jss5 {
  background-color: var(--green-bright) !important;
}
.makeStyles-tabs-2 {
  border-right: 1px solid var(--lightest-navy) !important;
}
#experience .MuiTab-root {
  padding: 6px 20px;
}
.MuiButtonBase-root:focus {
  color: var(--green-bright) !important;
}

ul {
  list-style: none;
  margin-left: -40px;
}

.job-description li::before {
  content: "▹    ";
  color: var(--green-bright);
  position: absolute;
  left: 0;
}

.job-description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 18px;
  color: var(--slate);
}

.job-description {
  padding-top: 24px;
  max-width: 650px;
  font-family: "NTR", sans-serif;
  font-size: 20px;
}

.job-description a,
.job-description b {
  font-size: 19px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  #experience {
    padding-left: unset;
    padding-right: unset;
  }

  .jss1 {
    height: unset !important;
    flex-direction: column !important;
  }

  .makeStyles-root-1 {
    height: unset !important;
    width: unset;
    flex-direction: column;
  }

  .joblist-job-title {
    font-size: 22px;
  }

  .joblist-job-company {
    font-size: 22px;
  }
}

@font-face {
  font-family: "NTR";
  font-weight: 200;
  font-display: fallback;
  src: url("/fonts/NTR-Regular.ttf") format("truetype");
}

#about {
  padding-left: 15%;
  padding-right: 25%;
  min-height: 60vh;
}

.about-description ul {
  padding-top: 20px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 10px));
}

.about-description li {
  position: relative;
  padding-left: 20px;
  font-size: 18px;
  color: var(--slate);
}

.about-description li::before {
  content: "▹    ";
  color: var(--green-bright);
  position: absolute;
  left: 0;
}

.about-content {
  display: flex;
  flex-direction: row;
}

.about-description {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  max-width: 600px;
  font-size: 22px;
  text-align: justify;
  margin: 0;
}

.about-description a,
.about-description b {
  font-size: 21px;
  font-weight: bold;
}

.about-image {
  padding-left: 40px;
}

.about-image img {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}

@media only screen and (max-width: 788px) {
  .about-image img {
    max-width: 200px;
  }
}

.about-image:hover {
  transform: translateY(-7px);
}

@media only screen and (max-width: 600px) {
  #about {
    height: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: 30%;
  }

  .about-description {
    font-size: 18px;
    text-align: justify;
  }

  .about-description a,
  .about-description b {
    font-size: 17px;
  }

  .about-image img {
    display: none;
  }

  .about-content {
    display: unset;
  }
}

#projects {
  width: 100%;
  padding: 0 15%; /* Match the padding from intro.css */
  margin: 0;
}

.section-header {
  display: flex;
  align-items: center;
  padding-bottom: 42px;
  width: 100%;
  position: relative;
}

.section-header::after {
  content: "";
  display: block;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  margin-top: 0;
  background-color: var(--lightest-navy);
}

.section-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 46px;
  font-weight: bold;
  white-space: nowrap;
}

.projects-wrapper {
  width: 100%;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
  gap: 30px;
  padding: 40px 0;
}

.project-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
}

.project-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

.project-content {
  padding: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.project-title {
  font-family: "NTR", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--lightest-slate);
  margin: 0;
}

.project-links {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.project-link {
  color: var(--green-bright);
  transition: color 0.2s ease;
}

.project-link:hover {
  color: #9ffff1;
}

.project-description {
  color: var(--slate);
  line-height: 1.6;
  font-family: "NTR", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  flex-grow: 1;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
  margin-top: auto;
}

.tech-tag {
  color: var(--green-bright);
  font-size: 14px;
  font-family: "NTR", sans-serif;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 600px) {
  #projects {
    padding: 0 24px;
  }
  
  .section-header::after {
    display: none;
  }
  
  .section-title {
    font-size: 32px;
  }

  .project-container {
    grid-template-columns: 1fr;
  }
  
  .project-card {
    max-width: 100%;
  }
}


/* #projects {
  width: 100%;
  padding: 0 15%;
  margin: 0;
}

.section-header {
  display: flex;
  align-items: center;
  padding-bottom: 42px;
  width: 100%;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.project-card {
  background: var(--bg-secondary);
  border-radius: 16px;
  transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-card:hover {
  transform: translateY(-5px);
  background: var(--bg-tertiary);
}

.project-image-container {
  width: 100%;
  height: 250px; /* Increased from 200px 
  overflow: hidden;
  position: relative;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

.project-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 80%; /* This ensures the content takes full height 
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.project-title {
  font-family: var(--font-display);
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.project-links {
  display: flex;
  gap: 16px;
}

.project-link {
  color: var(--accent);
  transition: all 0.2s ease;
}

.project-link:hover {
  color: var(--text-primary);
  transform: translateY(-2px);
}

.project-description {
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: 16px;
  white-space: pre-line; /* This handles the \n newlines 
  margin: 10px 0; /* Adjust margins 
  white-space: pre-line;
  margin-bottom: auto; /* This pushes the tech stack down 
  flex-grow: 1;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: auto;
}

.tech-tag {
  color: var(--accent);
  font-size: 13px;
  font-family: var(--font-mono);
  background-color: var(--green-tint);
  padding: 4px 8px;
  border-radius: 4px;
}

/* Navigation buttons 
.nav-button {
  background: none;
  border: 1px solid var(--accent);
  color: var(--accent);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.nav-button:hover {
  background: var(--green-tint);
}

.nav-button.prev {
  position: absolute;
  left: -60px;
}

.nav-button.next {
  position: absolute;
  right: -60px;
}

/* Carousel indicators 
.carousel-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--text-secondary);
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.indicator.active {
  opacity: 1;
  background: var(--accent);
  width: 24px;
  border-radius: 4px;
}

@media only screen and (max-width: 1200px) {
  .project-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  #projects {
    padding: 0 24px;
  }
  
  .project-container {
    grid-template-columns: 1fr;
  }
  
  .nav-button.prev {
    left: -40px;
  }
  
  .nav-button.next {
    right: -40px;
  }
} */
#credits {
  display: flex;
  align-items: center;
  padding-top: 100px;
}

.ending-credits {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding-bottom: 40px;
}

@font-face {
  font-family: "NTR";
  font-weight: 200;
  font-display: fallback;
  src: url("/fonts/NTR-Regular.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

/* Animation */

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.App {
  background-color:#131939;
  display: flex;
  flex-direction: row;
  /* padding: 36px 76px; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#content {
  /* padding: 80px 200px; */
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}
.rs-steps-horizontal .rs-steps-item-content {
  text-align: left;
}

::selection {
  background: var(--lightest-navy); /* WebKit/Blink Browsers */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.section-header {
  display: flex;
  padding-bottom: 42px;
  width: 100vw;
}

.section-header::after {
  content: "";
  display: block;
  width: 300px;
  margin-top: 39px;
  height: 1px;
  margin-left: 20px;
  background-color: var(--lightest-navy);
}

.section-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 46px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .App {
    flex-direction: column;
    padding: 40px 24px;
  }

  #content {
    padding: 10px 10px;
    width: 100%;
  }

  .section-header {
    width: unset;
  }

  .section-header::after {
    display: none;
  }

  .section-title {
    font-size: 32px;
  }
}

/* Import modern fonts */

/* Theme variables - these power our theme system */
:root {

  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --font-display: 'Space Grotesk', var(--font-sans);
  --font-mono: 'Fira Code', monospace;
  /* Color palette */
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);

  /* Light theme defaults */
  --bg-primary: #ffffff;
  --bg-secondary: var(--white);
  --text-primary: var(--navy);
  --text-secondary: var(--slate);
  --accent: var(--green-bright);

  /* Typography */
  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --font-heading: 'Space Grotesk', var(--font-sans);
  --font-mono: 'Fira Code', monospace;
}

/* Dark theme colors */
.dark-theme {
  --bg-primary: var(--dark-navy);
  --bg-secondary: var(--navy);
  --text-primary: var(--lightest-slate);
  --text-secondary: var(--light-slate);
  --accent: var(--green-bright);
  
}

/* Basic styles */
html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: var(--font-sans);
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0;
  padding: 0;
}

/* Animation classes */
.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* App layout */
.App {
  background-color: var(--bg-primary);
  min-height: 100vh;
}

#content {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 50px;  /* Add padding instead of centering */
}

/* Typography styles */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--text-primary);
  margin: 0;
  padding: 0;
}

.intro-text {
  font-family: var(--font-heading);
  font-size: 70px;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.intro-subtitle {
  font-family: var(--font-sans);
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-secondary);
  max-width: 540px;  /* Control width of text block */
}

/* Section styling */
.section-header {
  display: flex;
  padding-bottom: 42px;
  width: 100vw;
  align-items: flex-start;  /* Align to top instead of center */
}

.section-title {
  font-family: var(--font-heading);
  color: var(--text-primary);
  font-size: 46px;
  font-weight: 600;
}

/* Button styles */
.email-link {
  font-family: var(--font-mono);
  background-color: transparent;
  border: 1px solid var(--green-bright);
  border-radius: 4px;
  padding: 1.25rem 1.75rem;
  font-size: 14px;
  color: var(--green-bright);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
  margin-top: 50px;
  display: inline-block;
}

/* Mobile responsiveness */
@media only screen and (max-width: 768px) {
  #content {
    padding: 0 25px;
  }
  
  .intro-text {
    font-size: 40px;
  }
  
  .section-header {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  #content {
    padding: 0 20px;
  }
  
  .intro-text {
    font-size: 32px;
  }
}


