/* Import modern fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500&display=swap');

/* Theme variables - these power our theme system */
:root {

  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --font-display: 'Space Grotesk', var(--font-sans);
  --font-mono: 'Fira Code', monospace;
  /* Color palette */
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);

  /* Light theme defaults */
  --bg-primary: #ffffff;
  --bg-secondary: var(--white);
  --text-primary: var(--navy);
  --text-secondary: var(--slate);
  --accent: var(--green-bright);

  /* Typography */
  --font-sans: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --font-heading: 'Space Grotesk', var(--font-sans);
  --font-mono: 'Fira Code', monospace;
}

/* Dark theme colors */
.dark-theme {
  --bg-primary: var(--dark-navy);
  --bg-secondary: var(--navy);
  --text-primary: var(--lightest-slate);
  --text-secondary: var(--light-slate);
  --accent: var(--green-bright);
  
}

/* Basic styles */
html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: var(--font-sans);
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0;
  padding: 0;
}

/* Animation classes */
.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

/* App layout */
.App {
  background-color: var(--bg-primary);
  min-height: 100vh;
}

#content {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 50px;  /* Add padding instead of centering */
}

/* Typography styles */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--text-primary);
  margin: 0;
  padding: 0;
}

.intro-text {
  font-family: var(--font-heading);
  font-size: 70px;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.intro-subtitle {
  font-family: var(--font-sans);
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-secondary);
  max-width: 540px;  /* Control width of text block */
}

/* Section styling */
.section-header {
  display: flex;
  padding-bottom: 42px;
  width: 100vw;
  align-items: flex-start;  /* Align to top instead of center */
}

.section-title {
  font-family: var(--font-heading);
  color: var(--text-primary);
  font-size: 46px;
  font-weight: 600;
}

/* Button styles */
.email-link {
  font-family: var(--font-mono);
  background-color: transparent;
  border: 1px solid var(--green-bright);
  border-radius: 4px;
  padding: 1.25rem 1.75rem;
  font-size: 14px;
  color: var(--green-bright);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
  margin-top: 50px;
  display: inline-block;
}

/* Mobile responsiveness */
@media only screen and (max-width: 768px) {
  #content {
    padding: 0 25px;
  }
  
  .intro-text {
    font-size: 40px;
  }
  
  .section-header {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  #content {
    padding: 0 20px;
  }
  
  .intro-text {
    font-size: 32px;
  }
}

